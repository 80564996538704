import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from 'components/Button';
import CTA from 'components/CTA';
import styles from './heroSection.module.scss';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';

const buttonProps = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

const HeroSection = ({
  imagePath,
  mobileImagePath,
  title,
  leftButtons,
  description,
  subTitles,
  footer,
  callToActions,
  image,
  isTextWithOrPrefix,
  titleWithoutMargin,
  titleFullWidth,
  children,
  onClick,
  subTitle,
}) => (
  <div
    className={`${styles.appHeroSection}`}
    onClick={onClick && onClick}
    role="presentation"
  >
    {imagePath && (
      <picture>
        <source
          media="(max-width:550px)"
          srcSet={mobileImagePath || imagePath}
        />
        <img src={imagePath} alt={title} />
      </picture>
    )}

    {image && (
      <GatsbyImage
        image={image?.fluid}
        title={image?.title}
        className={styles.gatsbyImageWrapper}
      />
    )}

    <div
      className={`${styles.wrapper} ${
        titleFullWidth ? styles.wrapperFullWidth : ''
      }`}
    >
      {title && <h1 className={titleWithoutMargin ? 'mb-0' : ''}>{title}</h1>}
      {subTitle && <h2>{subTitle}</h2>}
      {description && (
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      )}
      {subTitles && subTitles.length && (
        <div className={styles.subTitlesGroupWrapper}>
          {subTitles.map((subTitle, index) => {
            if (Array.isArray(subTitle)) {
              return (
                <div
                  className={styles.subTitles}
                  key={`subTitleContainer${index}`}
                >
                  {subTitle &&
                    subTitle.length > 0 &&
                    subTitle.map((row, index) => (
                      <p key={`subTitleWrapper${index}`}>{row.title}</p>
                    ))}
                </div>
              );
            } else {
              return (
                <div
                  className={styles.subTitlesGroup}
                  key={`subTitleContainer${index}`}
                >
                  {subTitle.bold ? (
                    <p className={styles.subTitles}>{subTitle.title}</p>
                  ) : (
                    <h2 key={`subTitleContainer${index}`} className="mb-0">
                      {subTitle.title}
                    </h2>
                  )}
                </div>
              );
            }
          })}
        </div>
      )}

      <div className={styles.heroButtonWrapper}>
        {leftButtons &&
          leftButtons.map((button, index) => (
            <Button
              type="app-transparent-white-button"
              key={`heroSectionButton_${index}`}
              handleClick={() =>
                _.isFunction(button.onClick) && button.onClick()
              }
              linkAsButton={button.onClick ? false : true}
              path={button.path}
            >
              {button.title}
            </Button>
          ))}
        {callToActions?.length && (
          <CTA
            isTextWithOrPrefix={isTextWithOrPrefix}
            callToActions={callToActions}
          />
        )}
      </div>
      {footer && (
        <Button
          type="app-white-link-button"
          handleClick={() => _.isFunction(footer.onClick) && footer.onClick()}
        >
          {footer.title}
        </Button>
      )}
    </div>
    {children}
  </div>
);

HeroSection.propTypes = {
  imagePath: PropTypes.string,
  mobileImagePath: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.string,
  leftButtons: PropTypes.arrayOf(PropTypes.shape(buttonProps)),
  footer: PropTypes.shape(buttonProps),
};

export default HeroSection;
